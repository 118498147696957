import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';

import { openHelper } from '/common/Helper';
import { IRouteProps } from '/Router/models';
import { uiToolkit } from '/store';

import { useHintStyles } from './styles';

export function HintEnhancer({ children, helperFile }: IRouteProps) {
  const isHint = useSelector(uiToolkit.selectors.isHints);
  const classes = useHintStyles();

  if (!isHint) {
    return <>{children}</>;
  }

  const handleOpenHelper = () => openHelper({ path: helperFile });
  return (
    <>
      <IconButton color='primary' className={classes.helperIcon} onClick={handleOpenHelper} size='large'>
        <HelpOutlineIcon />
      </IconButton>
      {children}
    </>
  );
}

HintEnhancer.canEnhanced = (props: IRouteProps): boolean => !!props.helperFile;
