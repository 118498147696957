import { DateTime } from 'luxon';
import ReactGA from 'react-ga';
import NetworkNotifier from 'react-network-notifier';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { ThemeProvider } from '/common';
import { ReactQueryClientProvider } from '/common/ReactQueryClientProvider';
import { OnlineUsersService } from '/common/buzzer/OnlineUsersService';
import { ConfirmDialogWrapper } from '/common/ConfirmDialog';
import { log } from '/helpers';
import { StoreProvider } from '/store';
import { StorageProvider } from '/storage';
import { TransportContext } from '/api/transport';
import { PageTitle } from '/common/PageTitle';
import { KBarCustomProvider } from '/common/Kbar';

import { services2, ServicesContext } from './api';
import { Snackbar } from './common/SnackBar';
import { Router } from './Router';

import './helpers/adblock';
import './styles/App.scss';

localStorage.setItem('debug', 'app:*');

const prod = process.env.NODE_ENV === 'production';
const mode = !prod
  ? `%c[DEVELOPMENT]%c ${DateTime.utc().toLocal().toString()}`
  : `%c[BUILD]%c ${__CI_BUILD__ || 'local'}`;
const gaCode = __GA_CODE__ || 'UA-000000-01';

log('root', mode, 'background: #222; color: #bada55', 'color: inherit');
log('root', `%c[CONFIG]%c ${__CI_CONFIG__}`, 'background: #3f51b5; color: #fff', 'color: inherit');
log('root', `%c[SDK]%c ${__SDK_VERSION__}`, 'background: #e65100; color: #fff', 'color: inherit');

if (prod) {
  ReactGA.initialize(gaCode);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export const App = () => (
  <StorageProvider>
    <StoreProvider>
      <ThemeProvider>
        <ServicesContext.Provider value={{ services2 }}>
          <TransportContext.Provider value={services2.transport}>
            <ReactQueryClientProvider>
              <KBarCustomProvider>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <Snackbar>
                    <PageTitle />
                    <Router />
                    <ConfirmDialogWrapper />
                    <OnlineUsersService />
                    <NetworkNotifier />
                  </Snackbar>
                </LocalizationProvider>
              </KBarCustomProvider>
            </ReactQueryClientProvider>
          </TransportContext.Provider>
        </ServicesContext.Provider>
      </ThemeProvider>
    </StoreProvider>
  </StorageProvider>
);
