import { lazy } from 'react';

export const Support = lazy(() =>
  import(/* webpackChunkName: "Support" */ '/component/Support').then(({ SupportRoutes }) => ({
    default: SupportRoutes,
  }))
);
export const Playground = lazy(() =>
  import(/* webpackChunkName: "Playground" */ '/component/Playground/PlaygroundRoutes').then(
    ({ PlaygroundRoutes }) => ({ default: PlaygroundRoutes })
  )
);
export const Files = lazy(() =>
  import(/* webpackChunkName: "Files" */ '/component/Files/FilesRoutes').then(({ FilesRoutes }) => ({
    default: FilesRoutes,
  }))
);
export const Apps = lazy(() =>
  import(/* webpackChunkName: "Apps" */ '/component/Apps/AppsRoutes').then(({ AppsRoutes }) => ({
    default: AppsRoutes,
  }))
);
export const Device = lazy(() =>
  import(/* webpackChunkName: "Device" */ '/component/Device/DeviceRoutes').then(({ DeviceRoutes }) => ({
    default: DeviceRoutes,
  }))
);
export const Configuration = lazy(() =>
  import(/* webpackChunkName: "Configuration" */ '/component/Configuration/ConfigurationRoutes').then(
    ({ ConfigurationRoutes }) => ({ default: ConfigurationRoutes })
  )
);
export const Economy = lazy(() =>
  import(/* webpackChunkName: "Economy" */ '/component/Economy/EconomyRoutes').then(({ EconomyRoutes }) => ({
    default: EconomyRoutes,
  }))
);
export const Social = lazy(() =>
  import(/* webpackChunkName: "Social" */ '/component/Social/SocialRoutes').then(({ SocialRoutes }) => ({
    default: SocialRoutes,
  }))
);
export const Analytics = lazy(() =>
  import(/* webpackChunkName: "Analytics" */ '/component/Analytics/AnalyticsRoutes').then(({ AnalyticsRoutes }) => ({
    default: AnalyticsRoutes,
  }))
);
export const LiveOps = lazy(() =>
  import(/* webpackChunkName: "LiveOps" */ '/component/LiveOps/LiveOpsRoutes').then(({ LiveOpsRoutes }) => ({
    default: LiveOpsRoutes,
  }))
);
export const UA = lazy(() =>
  import(/* webpackChunkName: "UA" */ '/component/UA/UARoutes').then(({ UARoutes }) => ({
    default: UARoutes,
  }))
);
export const KeysManager = lazy(() =>
  import(/* webpackChunkName: "KeysManager" */ '/component/ApiKeysManager/ApiKeysManagerRoutes').then(
    ({ ApiKeysManagerRoutes }) => ({ default: ApiKeysManagerRoutes })
  )
);
export const MLRoute = lazy(() =>
  import(/* webpackChunkName: "ML" */ '/component/ML/MLRoutes').then(({ MLRoutes }) => ({
    default: MLRoutes,
  }))
);
export const Services = lazy(() =>
  import(/* webpackChunkName: "Services" */ '/component/Services/ServicesRoutes').then(({ ServicesRoutes }) => ({
    default: ServicesRoutes,
  }))
);
export const Corp = lazy(() =>
  import(/* webpackChunkName: "Corp" */ '/component/Corp/CorpRoutes').then(({ CorpRoutes }) => ({
    default: CorpRoutes,
  }))
);
export const UserProfileRoute = lazy(() =>
  import(/* webpackChunkName: "UserProfile" */ '/component/UserProfile').then(({ UserProfile }) => ({
    default: UserProfile,
  }))
);
export const UserStatus = lazy(() =>
  import(/* webpackChunkName: "StatusPage" */ '/component/Status').then(({ Status }) => ({
    default: Status,
  }))
);
export const BillingRoute = lazy(() =>
  import(/* webpackChunkName: "Billing" */ '/component/Billing/Billing').then(({ Billing }) => ({
    default: Billing,
  }))
);
export const LoginRoute = lazy(() =>
  import(/* webpackChunkName: "Login" */ '../component/Login/Login').then(({ Login }) => ({
    default: Login,
  }))
);
export const ResetPasswordRoute = lazy(() =>
  import(/* webpackChunkName: "ResetPassword" */ '../component/Login/ResetPassword').then(({ ResetPassword }) => ({
    default: ResetPassword,
  }))
);
export const LazyCalendarView = lazy(() =>
  import(/* webpackChunkName: "CalendarView" */ '/shared/AppEntityCalendar/CalendarView/Calendar').then((module) => ({
    default: module.Calendar,
  }))
);
