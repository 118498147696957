import { FC } from 'react';

import { HintEnhancer } from '/Router/Wrappers/HintEnhancer';
import { SplashScreenEnhancer } from '/Router/Wrappers/SplashScreenEnhancer';
import { IRouteProps } from '/Router/models';

export interface IEnhancerComponent extends FC<IRouteProps> {
  canEnhanced: (props: IRouteProps) => boolean;
}

export const enhancerComponents: IEnhancerComponent[] = [HintEnhancer, SplashScreenEnhancer];

export { HintEnhancer, SplashScreenEnhancer };
