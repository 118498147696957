import { makeStyles } from '@mui/styles';
import { Palette, Theme } from '@mui/material';

import { ThemeProvider } from '/common';
import { ThemeMode } from '/common/models';
import { headerHeight } from '/common/Header/styles';
import { collapsedNavSidebarWidth, navSidebarWidth } from '/common/NavSidebar/styles';

export const useSplashScreenStyles = makeStyles((theme: Theme) => {
  const themePalette: Palette = theme.palette;

  return {
    splashScreen: {
      position: 'fixed',
      top: `${headerHeight}px`,
      height: '100%',
      zIndex: 1101,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255,255,255,.9)',
      margin: '0 -15px',
    },
    sidebarOpen: {
      width: `calc(100% - ${navSidebarWidth}px)`,
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
    },
    sidebarClosed: {
      width: `calc(100% - ${collapsedNavSidebarWidth}px)`,
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
    },
    splashScreenText: {
      textAlign: 'center',
    },
    splashScreenBody: {} /* stylelint-disable-line block-no-empty */,
    splashScreenTitle: {
      color: ThemeProvider.getAppropriateStyles(
        themePalette.mode as ThemeMode,
        themePalette.primary.main,
        themePalette.text.primary
      ),
    },
  };
});
