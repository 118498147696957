import { FC } from 'react';

import { ProtectedRoute } from './ProtectedRoute';
import { IRouteProps } from './models/IRouteProps';
import EnhancedRoute from './EnhancedRoute';

const Route: FC<IRouteProps> = (props) => {
  const { secure = true, i } = props;

  return secure ? <ProtectedRoute key={i} {...props} /> : <EnhancedRoute key={i} {...props} />;
};

export default Route;
