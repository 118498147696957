import { FC } from 'react';
import { Router as AppRouter } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { ErrorBoundary } from '/component/ErrorBoundary';

import { history } from './history';
import { RoutesList } from './RoutesList';
import { routesConfig } from './routeConfig';

export const Router: FC = () => {
  const queryClient = useQueryClient();
  return (
    <AppRouter history={history}>
      <ErrorBoundary queryClient={queryClient}>
        <RoutesList routes={routesConfig} />
      </ErrorBoundary>
    </AppRouter>
  );
};
