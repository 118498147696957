import { makeStyles } from '@mui/styles';

export const useHintStyles = makeStyles({
  helperIcon: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 1101,
  },
});
