import { FC, memo, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AccessLevel, PermModule, Perms, UserRole } from '@playq/octopus2-auth';

import { appNameKey } from '/constants';
import { appToolkit, authToolkit, IAuthState, systemRehydrate } from '/store';
import { getUrlAppName } from '/helpers';
import { snackbarService } from '/common/snackbarService';
import { DefaultFallback } from '/common/Loadable';

import EnhancedRoute from './EnhancedRoute';
import { history } from './history';
import { IRouteGuard, IRouteProps } from './models/IRouteProps';

function checkMemberAccess(userAccess: AccessLevel, routeAccess: AccessLevel): boolean {
  switch (routeAccess) {
    case AccessLevel.WriteLive:
      return userAccess === AccessLevel.WriteLive;
    case AccessLevel.Write:
      return userAccess === AccessLevel.WriteLive || userAccess === AccessLevel.Write;
    case AccessLevel.Read:
      return (
        userAccess === AccessLevel.WriteLive || userAccess === AccessLevel.Write || userAccess === AccessLevel.Read
      );
    default:
      return false;
  }
}

function getModuleAccess(role: UserRole, access: Perms['access'], module: string) {
  const isAdmin = role === UserRole.Admin || role === UserRole.SuperAdmin;
  return isAdmin ? AccessLevel.WriteLive : access[module];
}

function hasRouteAccess(guard: IRouteGuard | undefined, userRole: UserRole, access: Perms['access']) {
  if (!guard) {
    return true;
  }

  const { role: routeRole = UserRole.None, access: routeAccess = AccessLevel.None, mod = PermModule.None } = guard;

  switch (routeRole) {
    case UserRole.None:
      return true;
    case UserRole.Member:
      if (userRole === UserRole.None) {
        return false;
      }
      break;
    case UserRole.Admin:
      if (userRole !== UserRole.Admin && userRole !== UserRole.SuperAdmin) {
        return false;
      }
      break;
    case UserRole.SuperAdmin:
      if (userRole !== UserRole.SuperAdmin) {
        return false;
      }
      break;
  }

  if (mod === PermModule.None || routeAccess === AccessLevel.None) {
    return true;
  }

  const moduleAccess = getModuleAccess(userRole, access, mod);
  return checkMemberAccess(moduleAccess, routeAccess);
}

export const ProtectedRoute: FC<IRouteProps> = memo((props) => {
  const { inverse, guard, computedMatch } = props;
  const appState = useSelector(appToolkit.selectors.state);
  const accessToken = useSelector(authToolkit.selectors.tokensSerialized).access;
  const loggedIn = useSelector(authToolkit.selectors.loggedIn);
  const loginPending = useSelector(authToolkit.selectors.pending);
  const role = useSelector(authToolkit.selectors.role);
  const access = useSelector(appToolkit.selectors.accessScope);
  const loginRedirectURL = useSelector(authToolkit.selectors.loginRedirectURL);

  const dispatch = useDispatch();

  useEffect(() => {
    const onFocus = () => {
      const persistedAuth = localStorage.getItem('auth');
      if (!persistedAuth) {
        return;
      }

      try {
        const authState = JSON.parse(persistedAuth) as IAuthState;
        const persistedAccessToken = authState.tokens.access;

        if (!persistedAccessToken || !accessToken) {
          return;
        }

        // compare accessToken from store and from localStorage. And if they are different logout current user
        if (persistedAccessToken !== accessToken) {
          dispatch(appToolkit.actions.resetApp());
          dispatch(systemRehydrate());
          history.push('/');
          snackbarService.info(`Changed user profile to ${authState.user.name}`);
        }
      } catch (e) {
        console.error(`Can't parse auth state`);
      }
    };

    window.addEventListener('focus', onFocus);

    return () => window.removeEventListener('focus', onFocus);
  }, [accessToken, dispatch]);

  const canLoad = inverse ? !loggedIn : loggedIn;

  if (!canLoad) {
    if (loginPending) {
      return DefaultFallback;
    }

    if (inverse) {
      return <Redirect to={loginRedirectURL ?? '/'} />;
    }

    dispatch(authToolkit.actions.updateLoginRedirectURL(props.location?.pathname));
    return <Redirect to='/login' />;
  }

  const isLoginRedirectURLHasApp = !!getUrlAppName(window.location.pathname);
  if (isLoginRedirectURLHasApp && !appState.app) {
    return DefaultFallback;
  }

  // @ts-expect-error Element implicitly has an 'any' type because type '{}' has no index signature.
  // check if route requires the application
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const routeWithApp = computedMatch && !!computedMatch.params[appNameKey];
  if (routeWithApp && appState.pending) {
    return DefaultFallback;
  }

  if (routeWithApp && !appState.app) {
    return <Redirect to='/' />;
  }
  const hasAccess = hasRouteAccess(guard, role, access);

  // if user has no access to route Redirect to rootPage
  if (!hasAccess) {
    console.warn(`Can't access the route`);
    return <Redirect to='/' />;
  }

  return <EnhancedRoute {...props} />;
});
