import { FC } from 'react';
import { Switch, Route as ReactRoute } from 'react-router-dom';

import { NotFound } from '/common/NotFound';

import Route from './Route';
import { IRouteProps } from './models/IRouteProps';

interface IRoutesListProps {
  routes: IRouteProps[];
}

export const RoutesList: FC<IRoutesListProps> = ({ routes = [] }) => (
  <Switch>
    {routes.map((route: IRouteProps, i: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <Route key={i} {...route} />
    ))}

    <ReactRoute component={NotFound} />
  </Switch>
);
