import { FC, memo, ReactNode } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { enhancerComponents, IEnhancerComponent } from '/Router/Wrappers';

import { IRouteProps } from './models/IRouteProps';

const getEnhancedComponent = (props: IRouteProps, rProps: RouteComponentProps): ReactNode => {
  const { component: Component } = props;
  return enhancerComponents.reduce(
    (WrappedComponent: ReactNode, Wrapper: IEnhancerComponent) => {
      const canEnhanced = Wrapper.canEnhanced(props);

      return canEnhanced ? <Wrapper {...props}>{WrappedComponent}</Wrapper> : WrappedComponent;
    },
    <Component routes={props.routes} {...rProps} />
  );
};

const EnhancedRoute: FC<IRouteProps> = memo((props) => {
  const { component: Component, ...rest } = props;

  const renderedComponent = (rProps: RouteComponentProps): ReactNode => {
    return getEnhancedComponent(props, rProps);
  };

  return <Route render={renderedComponent} {...rest} />;
});

export default EnhancedRoute;
